@media(max-width: 993px) {
    .techDescription {
        font-size: 12px;
    }

    #frontPageHeader {
        font-size: 48px;
    }

    #header {
        font-size: 48px;
        text-align: center;
    }

    #fpQuote {
        font-size: 36px;
    }

    #fpImgMain {
        margin-top: 20%;
    }

    #fpQuoteSecondary {
        font-size: 36px;
    }
}

@media (max-width: 1200px) {
    #header {
        font-size: 48px;
        text-align: center;
    }

    #frontPageHeader {
        text-align: center;
    }
    
}

@media (max-width: 762px) {
    #fpImgMain {
        margin-top: 0;
    }

    .fpSecondaryHeader {
        text-align: center;
    }

    #header {
        text-align: center;
    }

    #frontPageHeader {
        margin-top: 0;
        text-align: center;
    }
    
    .secondaryTexts {
        text-align: center;
    }

    #clientName {
        margin-top: 20px;
        text-align: center;
    }

    .webLinkContainer{
        text-align: center;
      }
}


@media (min-width: 574px) {
    .contacts {
        text-align: left;
    }
}

@media (max-width: 573px) {
    .contacts {
        text-align: center;
    }
}

@media (min-width: 417px) {
    .fpSections {
        margin: 25px 0;
     }

     .fpImgSecondary {
        margin: auto;
        display: block;
    }

    #fpQuote {
        margin-top: 70px;
    }
}

@media(max-width: 416px) {
    #frontPageHeader {
        font-size: 28px;
        text-align: center;
    }
    
    #header {
        font-size: 28px;
        text-align: center;
    }

    .fpSecondaryHeader {
        font-size: 18px;
        text-align: center;
    }

    .contacts {
        font-size: 18px;
    }

    #fpQuote {
        font-size: 18px;
        text-align: center;
        margin-top: 10px;
    }

    #fpQuoteSecondary {
        font-size: 24px;
        text-align: center;
    }

    #fpQuoteSecondary-desc {
        text-align: center;
    }

    .fpSections {
        margin: 10px;
     }

     .secondaryTexts {
         text-align: center;
     }

     .fpImgSecondary {
         margin: 20px 0;
     }
}

@media(min-width: 994px) {
    .techDescription {
        font-style: 16px;
    }

    #frontPageHeader {
        font-size: 56px;
    }

    #header {
        font-size: 56px;
        text-align: center;
    }

    #fpQuote {
        font-size: 24px;
        /* text-align: center; */
    }

    /* #fpImgMain {
        margin-top: 20%;
    } */

    #fpQuoteSecondary {
        font-size: 28px;
    }
}

#header {
    font-weight: bold;
    color: #2fC8B5;
    text-transform: uppercase;
}

#frontPageHeader {
    font-weight: bold;
    color: #2fC8B5;
    text-transform: uppercase;
    /* text-align: center; */
    font-family: 'Roboto', sans-serif;
    margin-bottom: 0;
}

.fpSecondaryHeader{
    color: #2fC8B5;
    font-weight: bold;
    /* text-align: left; */
    text-transform: uppercase;
    margin: 20px 0;
}

/* #landingSection {
    height: 60vh;
} */

#fpImgMain {
    width: 100%;
    height: auto;
}

.sectionHeaders {
    text-transform: uppercase;
    color: #2fC8B5;
    font-weight: bold;
    font-size: 36px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

#linkToServices {
    color: #303030;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 24px;
    text-align: left;
}

.serviceName {
    text-transform: uppercase;
    font-weight: bold;
    color: #2fC8B5;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

.techName {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Roboto', sans-serif;
    text-align: center;
}

.textTemplate {
    position: relative;
    width: 100%;
    height: 300px;
    margin: 30;
}


.images {
    width: 70%;
    height: auto;
    padding: 20px;
    display: block;
    margin:auto;
    align-items: center;
}

.image__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    color: #ffffff;
    font-family: 'Roboto', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
}

.image__overlay--blur {
    backdrop-filter: blur(5px);
}

.image__overlay--primary {
    background: #2fC8B5;
}

.image__overlay > * {
    transform: translateY(20px);
    transition: transform 0.25s;
}

.image__overlay:hover {
    opacity: 1;
}

.image__overlay:hover > * {
    transform: translateY(0);
}



.techDescription {
    margin: 0.4em;
    text-align: center;
}

.serviceDescription {
    font-size: 16px;
    padding: 24px;
    text-align: center;
}

.column {
    margin: 15px 0;
}

.serviceImages {
    height: 160px;
    width: auto;
    display:block;
    margin:auto;
    padding: 20px;
}

.fpImgSecondary {
    /* display: block; */
    /* margin: auto; */
    width: 400px;
    height: auto;
}
#aws-certified{
    position: relative;
    width: 220px;    
    margin-top: -77px;
}
.contacts {
    color: #000;
    font-size: 21px;
}

.contactsHighlight {
    font-weight: bold;
}

.sectionSummary {
    font-weight: bold;
    text-align: center;
}

#fpQuote {
    text-transform: uppercase;
    /* text-align: center; */
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
    /* margin-bottom: 40px; */
    color: #303030;
}

#fpQuoteSecondary {
    text-transform: uppercase;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    /* padding: 36px; */
}

#fpQuoteSecondary-desc {
    /* text-align: center; */

    margin: 20px 0;
    font-size: 18px;
}



.contactGif {
    width: 400px;
    height: auto;
}

#fpGif {
    height: 260px;
    width: auto;
    margin: auto;
    display: block;
}

.clientImg {
    height: 120px;
    width: auto;
    margin: auto;
    display: block;
}

.clientName {
    text-transform: uppercase;
    color: #303030;
    font-weight: bold;
    font-size: 23px;
}

.clientWebLink {
    text-transform: uppercase;
    color: #2fC8B5;
}

.webLinkContainer {
    width: 100%;
}

.blogContent{
    width: 100%;    
    border: 1px solid #eee;
}
@media(max-width: 991px) {
    #aws-certified {
        margin-top:0;
    }
}