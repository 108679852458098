.links {
    color: #303030;
    margin: 20px;
    text-transform: uppercase;
    font-size: 18px;
    font-family: 'Roboto', sans-serif;
    font-weight: 800;
    text-align: center;
}

.links:hover {
    text-decoration: none;
    color: #2fC8B5;
}

#logo {
    height: 100px;
    width: auto;
}

.navbar-light .navbar-nav .nav-link {
    color: #303030;
}

.nav-link {
    padding: 0;
    color: #303030;
}

.nav-link:hover {
    text-decoration: none;
    color: #2fC8B5;
}

.dropdown-menu {
    border: none;
    margin: 0;
}

.navbar-light .navbar-nav .active>.nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show>.nav-link {
    color: #303030;
}
