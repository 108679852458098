/* .App {
  text-align: center;
} */

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.wrapper {
  flex: 1;
}
