#footer {
    background-color: #2fC8B5;
    height: 100%;
    font-family: 'Raleway' sans-serif;
    color: white;
    padding-top: 50px;
}

ul {
    list-style-type: none;
    padding-left: 0;
}

li {
    text-align: center;
}

.footerLinks {
    text-transform: uppercase;
    color: white;
    font-size: 16px;
}

#footerContacts {
    text-align: center;
}

#copywrite {
    text-align: center;
    font-family: 'Roboto', sans-serif;
}